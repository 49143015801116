import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppConfig } from '@dougs/core/config';
import {
  CreatableTaskTemplate,
  TaskConfiguratorContextVariableAnyType,
  TaskForm,
  TaskFormInlineGroupValueItem,
  TaskTemplate,
} from '@dougs/task/dto';
import { CreatableTemplateTaskCacheService } from '../cache/creatable-template-task-cache.service';
import { TemplateTaskCacheService } from '../cache/template-task-cache.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateTaskHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly templateTaskCacheService: TemplateTaskCacheService,
    private readonly creatableTemplateTaskCacheService: CreatableTemplateTaskCacheService,
  ) {}

  getTaskTemplate(taskCode: string, taskVersion?: number): Observable<TaskTemplate> {
    let taskTemplate$: Observable<TaskTemplate> | null = this.templateTaskCacheService.getValue(
      `${taskCode}/${taskVersion}`,
    );

    if (!taskTemplate$) {
      taskTemplate$ = this.http
        .get<TaskTemplate>(
          `${AppConfig.settings.legacyApiServerUrl}/task-templates/${taskCode}`,
          taskVersion ? { params: { version: taskVersion } } : {},
        )
        .pipe(shareReplay(1));

      this.templateTaskCacheService.setValue(taskTemplate$, `${taskCode}/${taskVersion}`);
    }

    return taskTemplate$;
  }

  getCreatableTaskTemplate(): Observable<CreatableTaskTemplate[]> {
    let creatableTaskTemplate$: Observable<CreatableTaskTemplate[]> | null =
      this.creatableTemplateTaskCacheService.getValue();

    if (!creatableTaskTemplate$) {
      creatableTaskTemplate$ = this.http
        .get<CreatableTaskTemplate[]>(`${AppConfig.settings.legacyApiServerUrl}/task-templates/creatable`)
        .pipe(shareReplay(1));

      this.creatableTemplateTaskCacheService.setValue(creatableTaskTemplate$);
    }

    return creatableTaskTemplate$;
  }

  getTaskForm(taskId: number): Observable<TaskForm> {
    return this.http.get<TaskForm>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/form`);
  }

  changeTaskForm(taskId: number, items: TaskFormInlineGroupValueItem[] | TaskForm): Observable<TaskForm> {
    return this.http.post<TaskForm>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/form`, {
      form: items,
      dryRun: true,
    });
  }

  updateTaskForm(taskId: number, items: TaskFormInlineGroupValueItem[] | TaskForm): Observable<TaskForm> {
    return this.http.post<TaskForm>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/form`, {
      form: items,
    });
  }

  getTaskConfiguration(taskId: number): Observable<TaskForm> {
    return this.http.get<TaskForm>(`${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/configuration`);
  }

  getTaskConfigurationV2(taskId: number): Observable<TaskConfiguratorContextVariableAnyType[]> {
    return this.http.get<TaskConfiguratorContextVariableAnyType[]>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/configurationV2`,
    );
  }
}
