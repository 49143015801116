import { AppointmentType } from '@dougs/accounting/onboarding/dto';
import { ICustomerSurvey } from '@dougs/care/surveys/dto';
import { Company } from '@dougs/company/dto';
import { LegalForms, TaxRegimes } from '@dougs/core/config-back';
import { Attachment } from '@dougs/core/files';
import { PaymentMethodTypes } from '@dougs/subscription/dto';
import { Profile, User } from '@dougs/user/dto';
import { Comment } from './comment.dto';
import { TASK_LEGAL_STATE } from './legal-state.dto';
import { FileAttachmentSlot } from './task-template.dto';

export interface Task<Metadata = TaskMetadata> {
  id: number;
  version: number;
  companyId: number;
  company?: Company;
  overdue: boolean;
  dueDate: Date | string;
  enabledAt: Date;
  title: string;
  code: string;
  category: string;
  completedAt: Date | null;
  firstCompletedAt: Date;
  lastCommentAt: Date;
  targetTitle: string;
  billingAmountExcludingTaxes: number;
  isPriority: boolean;
  isService: boolean;
  abortedAt: Date | null;
  deletedAt: Date | null;
  createdAt: Date;
  startDate: Date | string;
  comments: Comment[];
  quoteStatus: 'ready' | 'quote' | 'project';
  isActive: boolean;
  isDelegated: boolean;
  hasRemarks: boolean;
  creatorId?: number;
  creator?: User;
  target: User;
  parentId: number | null;
  rootId: number | null;
  masterTaskId: number | null;
  policy: {
    isCompletable: boolean;
    isBillableAfterConfig?: boolean;
    allowDueDateUpdate?: boolean;
  };
  root?: Task;
  latestComment?: Comment;
  unseen: boolean;
  unseenMention: boolean;
  metadata: Metadata;
  billingInvoiceItems: TaskBillingInvoiceItem[];
  completer: {
    id: number;
    profile: Profile;
  };
  aborter?: {
    id: number;
    profile: Profile;
  };
  assignee: User | null;
  assigneeId: number | null;
  completionReasons: CompletionReason[];
  completionReasonCode: string;
  completionReasonMessage: string;
  abortionReasons: AbortionReason[];
  targetId: number;
  attachments: Attachment[];
  mention: Record<string, any> | null;
  subTaskCount: number;
  tasks: Task[];
  completedSubTaskCount: number;
  activeSubTaskTitle: string;
  activeSubTaskCode: string;
  formData?: {
    financialRiskLevel?: 'severe' | 'moderate' | 'low';
    customerImpactLevel?: 'severe' | 'moderate' | 'low';
    accountId?: number;
    source?: 'slack' | 'call' | 'captainContrat';
    [id: string]: any;
  };
  spawnedTaskId?: number;
  billableServiceIds?: number[];
  hasForm: boolean;
  isConfigurationTrigger: boolean; // Task Configurator V1
  isConfigurator: boolean; // Task Configurator V2
  isProcess: boolean;
  fileAttachmentSlots?: FileAttachmentSlot[];
  workload?: number;
  department?: TaskDepartment;
  index: number;
  status?: TASK_STATUS;
  onHold: boolean;
  dueDateInformation?: string;
  legalState?: TASK_LEGAL_STATE | null;
}

//TODO extract these metadata to corresponding specific task dto
export type TaskMetadata = {
  accountingYearId?: number;
  accountant?: { id: number; fullName: string; avatarUrl: string };
  avatarUrl?: string;
  activeCompanyId?: number;
  activeSubTaskCode?: string;
  preventNotifications: boolean;
  isLiquidation: boolean;
  isBillable?: boolean;
  billOnPendingInvoice?: boolean;
  closingDate: Date;
  completionPercent: number;
  paymentMethod: PaymentMethodTypes;
  activeUrl: string;
  declarationId: number | null;
  operationDate?: Date;
  countriesWithoutSellerTaxRegistration?: string;
  taxation?: {
    nationalSales: boolean;
    crossBorderSales: boolean;
    needsOss: boolean;
  };
  answers?: Record<string, unknown>;
  connectionId?: number;
  question?: string;
  billingInvoiceId?: number;
  billingInvoiceAmount?: number;
  billingInvoiceNumber?: number;
  billingInvoiceDate?: Date;
  paymentIntentClientSecret?: string;
  paymentMethodId?: string;
  attachmentIds?: number[];
  accountingSurveySeenAtLeastOnce?: boolean;
  reopeningData?: ReopeningData[];
  'accountingSurveyNotification-extraDays'?: boolean;
  calendlyUrl?: string;
  appointmentType?: AppointmentType;
  certificate?: TaskMetadataCertificate;
  completion?: { details: { accounting: { percent: number } } };
  completionModalLastSeenAt?: Date;
  shouldShowCancelButton?: boolean;
  hasPreviousAccountantMissingInformation?: boolean;
  behavior?: string;
  behaviorReason?: string;
  accountId?: number;
  reconciliationDate?: string;
  payslipDate?: string;
  legal?: {
    avatarUrl?: string;
    fullName?: string;
    id?: number;
  };
  billOnCompletion?: boolean;
  skipCustomerAcceptation?: boolean;
  regenerationReason?: string;
  changedPropertyLabel?: string;
  modalCompletionSurveyStats?: {
    startDate: string;
    endDate: string;
    hasValidated: boolean;
  }[];
  supervisionCount?: number;
  returningToSupervision?: boolean;
  supervisionCompleterId?: number;
  closingTaskId?: number;
  accountingNumber?: number;
  serviceTaskCode?: string;
  serviceTaskId?: number;
  capitalDepositData?: TaskMetadataCapitalDeposit;
  rootTaskId?: number;
  completedSignupStep?: number;
  plan?: string;
  trialEndsAt?: Date | null;
  isSocialCustomer?: boolean;
  companyConfig?: {
    legalForm?: keyof LegalForms;
    taxRegime?: keyof TaxRegimes;
    vatType?: string;
  };
  firstAccountingClosingDate?: Date | null;
  isCompanyFirstAccountingYear?: boolean;
  surveyCompletedAt?: Date | null;
  type?: string;
  surveyId?: number;
  dsnDate?: string;
  dsnAttachmentId?: number;
  collaborator?: { id: number; fullName: string; avatarUrl: string };
  workflowBypass?: {
    bypassed?: boolean;
    firstErrorAt?: Date | null;
  };
  taskToControlId?: number;
  survey?: ICustomerSurvey;
  payslipIds?: number[];
  ccnAnalysisAttachmentIds: number[];
  threshold?: number;
  thresholdReachedAmounts?: number;
  year?: number;
};

export interface TaskMetadataCapitalDeposit {
  providerProcessId: string;
  providerProcessRedirectionLink: string;
}

export interface TaskMetadataCertificate {
  partnerId: number;
  type: string;
}

export interface CompletionReason {
  code: string;
  label: string;
  messageRequired?: boolean;
  messageTitle?: string;
  messageWarning?: string;
}

export interface TaskBillingInvoiceItem {
  amount: number;
  description: string;
  fullDescription: string;
  id: number;
  quantity: number;
  totalAmount: number;
  taxPercent: number;
  totalAmountIncludingTaxes: number;
  billingInvoiceId: number;
  billingInvoiceNumber?: number;
  metadata?: TaskBillingInvoiceItemMetdata;
  isDraft?: boolean;
}

export interface TaskBillingInvoiceItemMetdata {
  paidAt?: Date | string;
}

export interface AbortionReason {
  label: string;
  code: string;
}

export interface ReopeningData {
  openerId: number;
  emailContent: string;
}

export const DEPARTMENTS = [
  'accounting',
  'charteredAccountant',
  'administrative',
  'care',
  'customer',
  'internalFinance',
  'legal',
  'social',
  'sales',
  'tech',
  'support',
  'fiscal',
  'other',
] as const;

export type TaskDepartment = (typeof DEPARTMENTS)[number];

export enum TaskScope {
  OWN = 'own',
  ALL_BUT_CUSTOMER = 'allButCustomer',
  ACCOUNTING = 'accounting',
  ADMINISTRATIVE = 'administrative',
  CARE = 'care',
  CUSTOMER = 'customer',
  INTERNAL_FINANCE = 'internalFinance',
  LEGAL = 'legal',
  SOCIAL = 'social',
  SALES = 'sales',
  TECH = 'tech',
  FISCAL = 'fiscal',
  OTHER = 'other',
}

export enum TASK_STATUS_FILTER {
  ACTIVE_OR_DELEGATED = 'activeOrDelegated',
  OVERDUE = 'overdue',
  COMPLETED = 'completed',
  ABORTED = 'aborted',
  FORECAST_IN_10_MONTHS = 'forecastIn10Months',
  ALL = 'all',
}

export enum TASK_STATUS {
  COMPLETED = 'completed',
  ABORTED = 'aborted',
  DELETED = 'deleted',
  DELEGATED = 'delegated',
  ON_HOLD = 'on_hold',
  ACTIVE = 'active',
  FORECAST = 'forecast',
}
