export enum TASK_LEGAL_STATE {
  TO_PRODUCE = `toProduce`,
  FORMALITIES = `formalities`,
  SIGNING = `signing`,
  CAPITAL_DEPOSIT = `capitalDeposit`,
  REGISTERING = `registering`,
  PENDING_DEPOSIT = `pendingDeposit`,
  PENDING_QUOTE = `pendingQuote`,
  WAITING_FOR_INFORMATION = `waitingForInformation`,
}

export enum TASK_LEGAL_STATE_GROUP {
  TO_DO = 'toDo',
  PENDING_FORMALITIES = 'pendingFormalities',
  WAITING_FOR_CUSTOMER = 'waitingForCustomer',
}
