import { TaskTemplate } from '../..';

const getDefaults = (): TaskTemplate => ({
  fileAttachmentSlots: [],
  version: 1,
  policy: {
    assignableRoles: [],
  },
  code: 'code',
  title: '',
});

export const getTaskTemplateMock = (p?: Partial<TaskTemplate>): TaskTemplate => ({
  ...getDefaults(),
  ...p,
});
